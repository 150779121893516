import React from 'react';

import Icon from './Icon';

export default function AppleIcon({
  className = '',
  text = 'Apple',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5135 6.84923C16.4144 6.92615 14.664 7.91245 14.664 10.1056C14.664 12.6422 16.8913 13.5397 16.9579 13.5619C16.9477 13.6166 16.6041 14.7909 15.7836 15.9875C15.052 17.0404 14.2879 18.0917 13.1255 18.0917C11.9632 18.0917 11.664 17.4165 10.3222 17.4165C9.01455 17.4165 8.5496 18.1139 7.48638 18.1139C6.42316 18.1139 5.6813 17.1396 4.82833 15.943C3.84032 14.5379 3.04205 12.3551 3.04205 10.2833C3.04205 6.96034 5.20268 5.19799 7.32912 5.19799C8.459 5.19799 9.40086 5.93985 10.1102 5.93985C10.7854 5.93985 11.8384 5.15355 13.1238 5.15355C13.611 5.15355 15.3614 5.19799 16.5135 6.84923ZM12.5136 3.74675C13.0452 3.116 13.4213 2.24081 13.4213 1.36561C13.4213 1.24425 13.411 1.12118 13.3888 1.02203C12.5239 1.05451 11.4948 1.59809 10.8743 2.31773C10.3872 2.87156 9.93247 3.74675 9.93247 4.6339C9.93247 4.76723 9.95469 4.90056 9.96495 4.9433C10.0196 4.95355 10.1085 4.96552 10.1974 4.96552C10.9735 4.96552 11.9495 4.44588 12.5136 3.74675Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
