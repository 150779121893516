import React from 'react';
import { os } from 'shared/utils/environment';

import MacFinderIcon from './MacFinderIcon';
import MonitorIcon from './MonitorIcon';
import WindowsIcon from './WindowsIcon';

export default function AppropriateDesktopAppIcon({
  text = 'Desktop app',
  className = '',
}: { text?: string; className?: string; }): JSX.Element {
  if (os.name === 'Mac OS') {
    return <MacFinderIcon className={className} text={text} />;
  }

  if (os.name === 'Windows') {
    return <WindowsIcon className={className} text={text} />;
  }

  return <MonitorIcon className={className} text={text} />;
}
