import React from 'react';

import Icon from './Icon';

export default function MacFinderIcon({
  text = 'Finder',
  className = '',
}: { text?: string; className?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.4093 26.6666H6.59067C5.16 26.6666 4 25.5066 4 24.076V7.92398C4 6.49331 5.16 5.33331 6.59067 5.33331H25.408C26.84 5.33331 28 6.49331 28 7.92398V24.0746C28 25.5066 26.84 26.6666 25.4093 26.6666V26.6666Z"
          stroke="currentColor"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 11.3333V13.3333"
          stroke="currentColor"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.3333 11.3333V13.3333"
          stroke="currentColor"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6666 17.2533H17.76"
          stroke="currentColor"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6946 5.33331C15.408 8.95331 14.6666 12.984 14.6666 17.2546"
          stroke="currentColor"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.7626 17.2546C17.7626 20.5066 18.0773 23.66 18.6666 26.6666"
          stroke="currentColor"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.6667 19.6666C18.9854 22.7773 13.0147 22.7773 9.33337 19.6666"
          stroke="currentColor"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}
