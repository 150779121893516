import React from 'react';

import Icon from './Icon';

export default function ImportIcon({
  className = '',
  text = 'Import',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.7463 6.99791C12.7463 6.5837 12.4106 6.24791 11.9963 6.24791C11.5821 6.24791 11.2463 6.5837 11.2463 6.99791V11.69L9.77545 10.2191C9.48255 9.92625 9.00768 9.92625 8.71479 10.2191C8.42189 10.512 8.42189 10.9869 8.71479 11.2798L11.4659 14.0309C11.7588 14.3238 12.2337 14.3238 12.5266 14.0309L15.2777 11.2798C15.5706 10.9869 15.5706 10.512 15.2777 10.2191C14.9848 9.92625 14.51 9.92625 14.2171 10.2191L12.7463 11.6899V6.99791Z"
          fill="currentColor"
        />
        <path
          d="M6.9978 14.7515C7.41202 14.7515 7.7478 15.0872 7.7478 15.5015V16.0017C7.7478 16.14 7.85992 16.2521 7.99822 16.2521H16.0016C16.1399 16.2521 16.252 16.14 16.252 16.0017V15.5015C16.252 15.0872 16.5878 14.7515 17.002 14.7515C17.4162 14.7515 17.752 15.0872 17.752 15.5015V16.0017C17.752 16.9684 16.9683 17.7521 16.0016 17.7521H7.99822C7.03149 17.7521 6.2478 16.9684 6.2478 16.0017V15.5015C6.2478 15.0872 6.58359 14.7515 6.9978 14.7515Z"
          fill="currentColor"
        />
        <path
          d="M7.99609 2.24625C4.82046 2.24625 2.24609 4.82061 2.24609 7.99624V16.0037C2.24609 19.1794 4.82045 21.7537 7.99609 21.7537H16.0036C19.1792 21.7537 21.7536 19.1794 21.7536 16.0037V7.99625C21.7536 4.82061 19.1792 2.24625 16.0036 2.24625H7.99609ZM3.74609 7.99624C3.74609 5.64903 5.64888 3.74625 7.99609 3.74625H16.0036C18.3508 3.74625 20.2536 5.64904 20.2536 7.99625V16.0037C20.2536 18.351 18.3508 20.2537 16.0036 20.2537H7.99609C5.64888 20.2537 3.74609 18.351 3.74609 16.0037V7.99624Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
