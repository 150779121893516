import React from 'react';

import Icon from './Icon';

export default function HelpIcon({
  className = '',
  text = 'Help',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.7907 9.06987C14.6932 7.71082 13.5233 6.67839 12.1599 6.75391C12.1357 6.75525 12.1115 6.75542 12.0873 6.75441C10.8544 6.70325 9.75965 7.53665 9.48076 8.73864C9.38714 9.14213 8.98415 9.39334 8.58065 9.29972C8.17716 9.2061 7.92596 8.80311 8.01957 8.39961C8.45872 6.50692 10.1746 5.19102 12.1132 5.25436C14.2922 5.15312 16.1536 6.81906 16.2894 9.00065C16.2904 9.01616 16.2909 9.03171 16.2909 9.04725C16.2909 10.5846 15.1758 11.5503 14.3788 12.1983C14.2827 12.2764 14.1912 12.3498 14.104 12.4197C13.7884 12.6728 13.5301 12.88 13.3234 13.096C13.0658 13.3652 13.0205 13.5184 13.0205 13.6105C13.0205 14.0248 12.6847 14.3605 12.2705 14.3605C11.8563 14.3605 11.5205 14.0248 11.5205 13.6105C11.5205 12.9422 11.8839 12.4307 12.2396 12.059C12.5219 11.764 12.8829 11.4751 13.2083 11.2147C13.2856 11.1529 13.3608 11.0927 13.4325 11.0344C14.2623 10.3598 14.7803 9.8087 14.7907 9.06987ZM13.2 17.6833C13.2053 17.7193 13.208 17.7561 13.208 17.7936C13.208 18.3128 12.7871 18.7337 12.2679 18.7337C11.7487 18.7337 11.3278 18.3128 11.3278 17.7936C11.3278 17.2744 11.7487 16.8534 12.2679 16.8534C12.3136 16.8534 12.3583 16.8575 12.4018 16.8654C12.8203 16.9257 13.1497 17.2616 13.2 17.6833Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
